.footer{padding-top: 36px;background-color: #373737;position: relative;}
.footer a{cursor: pointer;}
.footer .footerLeaf{position: absolute;right: 0;bottom: 0;width: 127px;height: 278px ;display: flex;}
.assurance {padding-bottom: 25px;border-bottom: 0.5px solid #747474;}
.assurance ul{display: flex;align-items: center;}
.assurance ul li{margin-left: 15px;padding: 15px;border-radius: 2px;border: solid 0.5px #747474;background-color: #3d3d3d;display: flex;align-items:center;width: 100%;position: relative;}
.assurance ul li .tileLeaf{position: absolute;right: 0;bottom: 0;;width: 51px;height: 69px;}
.assurance ul li:first-child{margin: 0;}
.assurance ul li figure{margin-right: 10px;width: 102px;height: 74px;display: flex;}
.assurance ul li figure img{object-fit: contain;max-width: 85px;width: 100%;}
.assurance ul li label{margin-bottom: 10px;display: block;font-size: 14px;color: #c9c9c9;}
.assurance ul li span{font-size: 12px; color: #c9c9c9;line-height: 20px;width: 100% !important;max-width: 190px;display: inherit;}
.otherResouresBx{position: relative;}
.otherResoures.rmvBdr{border-bottom: none;}
.otherResoures{padding: 25px 0px 25px 0px;margin-left: 145px;display: flex;}
.otherResoures figure{position: absolute;left: 0%;top: 25px;}
.otherResoures .heading {margin-bottom: 10px;font-size: 16px;color: #eb5976;}
.otherResoures .linkBoxes{width: 100%;}
.otherResoures li{font-size: 14px;color: #c9c9c9;line-height: 27px;}
.otherResoures li strong{display: block;}
.keppInTouch li:first-child{margin-left: 0;}
.keppInTouch li{margin-left: 26px;display: inline-block;line-height: 11px;}
.keppInTouch li a{cursor: pointer;}
.footerLocations{display: flex; gap: 86px;}
.locations{display: block}
.locations .locationHeading{color: #c9c9c9;font-size: 14px;}
.locations ul li{color: #757575;font-size: 13px;line-height: 25px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 237px;}


.copyrightSectn{padding: 15px 0px;border-top: 0.5px solid #747474;color: #fff;}
.slctCntry{display: flex;align-items: center;float: right;position: relative;top: -7px;}
.copyrightSectn ul{margin-left: 10px;display: inline-flex;}
.copyrightSectn ul li {margin-left: 5px;display: inline-flex;padding: 5px 8px;border-radius: 10px;align-items: center;cursor: pointer;border: 1px solid transparent;transition: 0.3s;}
.copyrightSectn ul li a{display: flex;}
.copyrightSectn ul li:hover{border: 1px solid #fff;transition: 0.3s;}
.copyrightSectn ul li.active{border: 1px solid #fff;}
.copyrightSectn ul li figure{position: relative;top: -1px;margin-right: 5px;}


@media screen and (max-width:768px) {
    .footer{padding-top: 15px;}
    .footer .footerLeaf{display: none;}
    .assurance{margin-bottom: 15px;padding-bottom: 0px;}
    .assurance ul{display: block;}
    .assurance ul li:first-child{margin: 0px 0px 16px;}
    .assurance ul li{margin: 0px 0px 16px;}
    .assurance ul li figure img{object-fit: contain;}
    .otherResoures{padding: 0;margin-left: 0;display: block;border: none;}
    .otherResoures figure{margin-bottom: 26px;position: relative;top: inherit;}
    .otherResoures .linkBoxes {padding-bottom: 15px;margin-bottom: 15px;border-bottom: 0.5px solid #747474;}
    .otherResoures.rmvBdr .linkBoxes{margin: 0;padding: 0;border: none;}
    .locations .locationHeading{padding-bottom: 10px;}
    .locations ul li{padding-bottom: 10px;line-height: 17px;max-width: 100%;}
    .footerLocations{padding-bottom: 5px;display: block;}
}

@media screen and (max-width:540px) {
    .assurance ul li figure{margin-right: 12px;}
    .assurance ul li span{font-size: 10px;}
    .slctCntry{float: inherit;position: inherit;display: inherit;}
    .copyrightSectn ul{margin: 15px 0px 15px;display: block;}
    .copyrightSectn ul li:first-child{margin: 0;}
    .copyrightSectn .cpyrightDtl{padding-top: 15px;border-top:  0.5px solid #747474;}
}